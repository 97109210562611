import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';

const ArticleLinkStyles = css`
  display: flex;
  width: 100%;
  height: 100%;
  background: none;
  color: inherit;
  border: none;
  text-decoration: none;
  :hover {
    background-color: none;
    color: inherit;
    border: none;
    text-decoration: none;
  }
`;

const ArticleLink = ({ children, internalLink, externalLink }) => {
  if (externalLink) {
    return (
      <a
        target={'_blank'}
        rel={'noreferrer'}
        href={externalLink}
        className={cx(ArticleLinkStyles)}
      >
        {children}
      </a>
    );
  }

  if (internalLink) {
    return (
      <Link to={internalLink} className={cx(ArticleLinkStyles)}>
        {children}
      </Link>
    );
  }
  return null;
};

export default ArticleLink;
