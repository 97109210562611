import React from 'react';
import Heading from '../../components/ui/Heading';
import Text from '../../components/ui/Text';
import Image from '@jetshop/ui/Image';
import Button from '../../components/ui/Button';
import { css, cx } from 'linaria';
import { theme } from '../../components/Theme';
import { toRem } from '../../helpers';
import useChildren from '../../hooks/useChildren';
import MaxWidth from '../../components/Layout/MaxWidth';

export default function InfoBlock(props) {
  const {
    title, // string
    align = 'left', // left | center | right
    image, // string
    imageAlt = '', // string
    imageAlign = 'left', // left | right | top
    linkUrl, // string
    linkText, // string,
    children, // Node
    fullWidth, // boolean
    withPadding //boolean
  } = useChildren(props);

  const content = props.content;

  return (
    <MaxWidth>
      <section
        className={cx(
          infoBlockClassName,
          align && `align-${align}`,
          image && 'withImage',
          image && imageAlign && `imageAlign-${imageAlign}`,
          linkUrl && linkText && 'withLink',
          fullWidth && 'fullWidth',
          withPadding && 'withPadding'
        )}
      >
        {image ? (
          <figure className="imageWrapper">
            <Image
              cover
              className="image"
              aspect="16:9"
              {...{ src: image }}
              alt={imageAlt}
            />
          </figure>
        ) : null}
        <div className="contentWrapper">
          {title ? (
            <Heading className="title" level={2}>
              {title}
            </Heading>
          ) : null}
          {content || children ? (
            <div className="content">
              {content?.type === 'html' && (
                <div
                  className="html"
                  dangerouslySetInnerHTML={{
                    __html: content?.value?.value || content?.value || null
                  }}
                />
              )}
              {content?.type === 'text' && (
                <Text>{content?.value?.value || content?.value}</Text>
              )}
              {children}
            </div>
          ) : null}
          {linkUrl && linkText ? (
            <footer className="footer">
              <Button className="button" href={linkUrl}>
                {linkText}
              </Button>
            </footer>
          ) : null}
        </div>
      </section>
    </MaxWidth>
  );
}
const infoBlockClassName = css`
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
  position: relative;
  z-index: 1;
  width: 100%;

  &.fullWidth::before {
    content: '';
    z-index: -1;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 100vw;
    transform: translateX(-50%);
    background-color: ${theme.colorScheme.superlightgrey};
  }
  &.align-center {
    text-align: center;
    align-items: center;
    .contentWrapper {
      align-items: center;
    }
  }
  &.align-right {
    text-align: right;
    align-items: end;
    .contentWrapper {
      align-items: end;
    }
  }
  ${theme.above.md} {
    &.imageAlign-left {
      flex-direction: row;
      align-items: center;
      .contentWrapper {
        padding-left: 2rem;
      }
    }
    &.imageAlign-right {
      flex-direction: row-reverse;
      align-items: center;
      .contentWrapper {
        padding-right: 2rem;
      }
    }
    &.imageAlign-top {
      .imageWrapper {
        flex: 1 1 100%;
      }
    }
  }
  ${theme.above.xl} {
    max-width: ${toRem(theme.widths.textContent)};
    margin-left: auto;
    margin-right: auto;
  }

  .imageWrapper {
    flex: 1 1 50%;
    margin: 0;
    width: 100%;
  }
  .contentWrapper {
    flex: 1 1 50%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;

    ${theme.above.xl} {
      max-width: 60em;
    }
  }
  .content {
    .html {
      line-height: 1.5rem;
      h1 {
        line-height: normal;
      }

      a {
        color: inherit;
      }
      p {
        margin: 0.5rem 0;
      }
      strong {
        font-weight: ${theme.fontWeights.medium};
      }
      ul {
        list-style: initial;
        padding: 1rem 0 1rem 1.5rem;
      }
    }
  }
  .footer {
    margin-top: 1rem;
  }
`;
