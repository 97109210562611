import React, { useState, useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import Image from '@jetshop/ui/Image';
import { Below } from '@jetshop/ui/Breakpoints';
import Styled from './Style';
import useChildren from '../../hooks/useChildren';
import CTALink from '../../components/ui/Link';
import ArticleLink from './ArticleLink';
import t from '@jetshop/intl';
import { theme } from '../../components/Theme';

const ArticleBlock = props => {
  const cleanedProps = useChildren(props);
  const articlesA = cleanedProps['articleIngressA'];
  const articlesB = cleanedProps['articleIngressB'];
  const articlesC = cleanedProps['articleIngressC'];
  const articlesSE = cleanedProps['articleIngressSEOSE'];
  const articlesNO = cleanedProps['articleIngressSEONO'];
  const articlesFI = cleanedProps['articleIngressSEOFI'];
  const articlesDK = cleanedProps['articleIngressSEODK'];

  const articles = [
    ...(articlesA || []),
    ...(articlesB || []),
    ...(articlesC || []),
    ...(articlesSE || []),
    ...(articlesNO || []),
    ...(articlesFI || []),
    ...(articlesDK || [])
  ].sort((a, b) => (a.prio > b.prio ? -1 : 1));

  const imageSizes = [1, 1, 1 / 2, 1 / 2, 1 / 2, 676];

  const readMore = t('Read more');
  var articleIndex = 0;

  const [showGuides, setShowGuides] = useState('');
  const [guidesActive, setGuidesActive] = useState(false);
  const [showNews, setShowNews] = useState('');
  const [newsActive, setNewsActive] = useState(false);
  const [showTests, setShowTests] = useState('');
  const [testsActive, setTestsActive] = useState(false);
  const [showTips, setShowTips] = useState('');
  const [tipsActive, setTipsActive] = useState(false);
  const [showBrand, setShowBrand] = useState('');
  const [brandActive, setBrandActive] = useState(false);

  const handleArticlesShow = type => {
    if (type === 'Guider') {
      setGuidesActive(!guidesActive);
      setShowGuides(!showGuides);
    }
    if (type === 'Nyheter') {
      setNewsActive(!newsActive);
      setShowNews(!showNews);
    }
    if (type === 'Tester') {
      setTestsActive(!testsActive);
      setShowTests(!showTests);
    }
    if (type === 'Tips') {
      setTipsActive(!tipsActive);
      setShowTips(!showTips);
    }
    if (type === 'Brand') {
      setBrandActive(!brandActive);
      setShowBrand(!showBrand);
    }
  };
  const { selectedChannel } = useContext(ChannelContext);
  var brandName = selectedChannel.name,
    brandNameArr = brandName.split('.');
  const articleBrand = brandNameArr[0];

  const artTextGuides = t('Guider');
  const artTextNews = t('Nyheter');
  const artTextTests = t('Tester');
  const artTextTips = t('Tips');

  var showGuidesButton = false;
  var showNewsButton = false;
  var showTestsButton = false;
  var showTipsButton = false;
  var showBrandButton = false;

  if (!articlesA && !articlesB && !articlesC) return null;

  articles.map(article => {
    const artType = article.articleType;
    if (artType === 'Guider') {
      showGuidesButton = true;
    }
    if (artType === 'Nyheter') {
      showNewsButton = true;
    }
    if (artType === 'Tester') {
      showTestsButton = true;
    }
    if (artType === 'Tips') {
      showTipsButton = true;
    }
    if (artType === 'Brand') {
      showBrandButton = true;
    }
    return null;
  });

  return (
    <Styled.ArticlesBlockWrapper>
      <Styled.ArticleTypeButtonsWrapper>
        {showGuidesButton ? (
          <Styled.ArticleTypeButton
            key={'Guider'}
            onClick={() => handleArticlesShow('Guider')}
            style={{
              backgroundColor:
                guidesActive === true ? theme.colorScheme.primary : ''
            }}
          >
            <Styled.ArticleTypeButtonLabel>
              {artTextGuides}
            </Styled.ArticleTypeButtonLabel>
          </Styled.ArticleTypeButton>
        ) : null}
        {showNewsButton ? (
          <Styled.ArticleTypeButton
            key={'Nyheter'}
            onClick={() => handleArticlesShow('Nyheter')}
            style={{
              backgroundColor:
                newsActive === true ? theme.colorScheme.primary : ''
            }}
          >
            <Styled.ArticleTypeButtonLabel>
              {artTextNews}
            </Styled.ArticleTypeButtonLabel>
          </Styled.ArticleTypeButton>
        ) : null}
        {showTestsButton ? (
          <Styled.ArticleTypeButton
            key={'Tester'}
            onClick={() => handleArticlesShow('Tester')}
            style={{
              backgroundColor:
                testsActive === true ? theme.colorScheme.primary : ''
            }}
          >
            <Styled.ArticleTypeButtonLabel>
              {artTextTests}
            </Styled.ArticleTypeButtonLabel>
          </Styled.ArticleTypeButton>
        ) : null}
        {showTipsButton ? (
          <Styled.ArticleTypeButton
            key={'Tips'}
            onClick={() => handleArticlesShow('Tips')}
            style={{
              backgroundColor:
                tipsActive === true ? theme.colorScheme.primary : ''
            }}
          >
            <Styled.ArticleTypeButtonLabel>
              {artTextTips}
            </Styled.ArticleTypeButtonLabel>
          </Styled.ArticleTypeButton>
        ) : null}
        {showBrandButton ? (
          <Styled.ArticleTypeButton
            key={'Brand'}
            onClick={() => handleArticlesShow('Brand')}
            style={{
              backgroundColor:
                brandActive === true ? theme.colorScheme.primary : ''
            }}
          >
            <Styled.ArticleTypeButtonLabel>
              {articleBrand}
            </Styled.ArticleTypeButtonLabel>
          </Styled.ArticleTypeButton>
        ) : null}
      </Styled.ArticleTypeButtonsWrapper>
      {articles.map(article => {
        const articleUrl = article.internalLinkUrl;
        const articleTitle = article.title;
        const articleIngressText = article.ingress;
        const articleHide = article.hide;
        const artType = article.articleType;
        const artImage = article.articleImage;
        var artTypeText;
        if (artType === 'Brand') {
          artTypeText = articleBrand;
        } else {
          artTypeText = t(artType);
        }
        if (
          articleHide === 'Show' &&
          ((showGuides && artType === 'Guider') ||
            (showNews && artType === 'Nyheter') ||
            (showTests && artType === 'Tester') ||
            (showTips && artType === 'Tips') ||
            (showBrand && artType === 'Brand') ||
            (!showGuides && !showNews && !showTests && !showTips && !showBrand))
        ) {
          articleIndex++;
          if (articleIndex === 1) {
            return (
              <Below breakpoint="lg">
                {matches =>
                  matches ? (
                    <Styled.ArticleItem>
                      <Styled.ArticleWrapper>
                        <ArticleLink internalLink={articleUrl}>
                          <Image
                            sizes={imageSizes}
                            aspect={'169:89'}
                            src={artImage}
                            fillAvailableSpace={true}
                            crop
                          >
                            <Styled.Badge>
                              <Styled.BadgeText>{artTypeText}</Styled.BadgeText>
                            </Styled.Badge>
                          </Image>
                        </ArticleLink>
                        <Styled.TextWrapper>
                          <Styled.ArticleTitle>
                            {articleTitle}
                          </Styled.ArticleTitle>
                          <Styled.ArticleText>
                            <div
                              className="html"
                              dangerouslySetInnerHTML={{
                                __html: articleIngressText || null
                              }}
                            />
                            <Styled.ArticleFade />
                          </Styled.ArticleText>
                        </Styled.TextWrapper>
                        <Styled.ArticleLink>
                          <CTALink internalLink={articleUrl}>
                            {readMore}
                          </CTALink>
                        </Styled.ArticleLink>
                      </Styled.ArticleWrapper>
                    </Styled.ArticleItem>
                  ) : (
                    <Styled.ArticleTopItem>
                      <Styled.ArticleTopWrapper>
                        <Styled.ArticleTopImage>
                          <ArticleLink internalLink={articleUrl}>
                            <Image
                              sizes={imageSizes}
                              aspect={'169:89'}
                              src={artImage}
                              fillAvailableSpace={true}
                              crop
                            >
                              <Styled.Badge>
                                <Styled.BadgeText>
                                  {artTypeText}
                                </Styled.BadgeText>
                              </Styled.Badge>
                            </Image>
                          </ArticleLink>
                        </Styled.ArticleTopImage>
                        <Styled.TextTopWrapper>
                          <Styled.ArticleTopTitle>
                            {articleTitle}
                          </Styled.ArticleTopTitle>
                          <Styled.ArticleTopText>
                            <div
                              className="html"
                              dangerouslySetInnerHTML={{
                                __html: articleIngressText || null
                              }}
                            />
                          </Styled.ArticleTopText>
                          <Styled.ArticleTopLink>
                            <CTALink internalLink={articleUrl}>
                              {readMore}
                            </CTALink>
                          </Styled.ArticleTopLink>
                        </Styled.TextTopWrapper>
                      </Styled.ArticleTopWrapper>
                    </Styled.ArticleTopItem>
                  )
                }
              </Below>
            );
          } else {
            return (
              <Styled.ArticleItem>
                <Styled.ArticleWrapper>
                  <ArticleLink internalLink={articleUrl}>
                    <Image
                      sizes={imageSizes}
                      aspect={'169:89'}
                      src={artImage}
                      fillAvailableSpace={true}
                      crop
                    >
                      <Styled.Badge>
                        <Styled.BadgeText>{artTypeText}</Styled.BadgeText>
                      </Styled.Badge>
                    </Image>
                  </ArticleLink>
                  <Styled.TextWrapper>
                    <Styled.ArticleTitle>{articleTitle}</Styled.ArticleTitle>
                    <Styled.ArticleText>
                      <div
                        className="html"
                        dangerouslySetInnerHTML={{
                          __html: articleIngressText || null
                        }}
                      />
                      <Styled.ArticleFade />
                    </Styled.ArticleText>
                  </Styled.TextWrapper>
                  <Styled.ArticleLink>
                    <CTALink internalLink={articleUrl}>{readMore}</CTALink>
                  </Styled.ArticleLink>
                </Styled.ArticleWrapper>
              </Styled.ArticleItem>
            );
          }
        } else {
          return null;
        }
      })}
    </Styled.ArticlesBlockWrapper>
  );
};

export default ArticleBlock;
